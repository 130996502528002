<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 16:22:17
 * @LastEditTime: 2021-06-29 16:52:27
-->
<template>
	<div class="layout">
		<el-container>
			<!-- 主体部分 -->
			<el-main :class="{'isIos-main':platform == 'ios','isAndroid-main': platform == 'android'}">
				<router-view></router-view>
			</el-main>
		</el-container>
	</div>
</template>
<script>
	export default {
		name: "mbRouteView",
		components: {},
		data() {
			return {
				platform: ""
			};
		},
		computed: {},
		methods: {},
		watch: {
			$route: {
				handler(val) {
					console.log("$router", val)
					if (val.meta.title) {
						let headTitle = ""
						headTitle = val.meta.title
						if (this.$dd.env.platform !== "notInDingTalk") {
							this.$dd.ready(() => {
								if (!val.meta.showHeader) {
									this.$dd.biz.navigation.hideBar({
										hidden: true, // true：隐藏，false：显示
									});
								} else {
									this.$dd.biz.navigation.hideBar({
										hidden: false, // true：隐藏，false：显示
									});
									// 设置标题
									dd.biz.navigation.setTitle({
										title: headTitle, //控制标题文本，空字符串表示显示默认文本
										onSuccess: function(result) {},
										onFail: function(err) {}
									});
								}
							})
						}
					}
				},
				immediate: true,
			}
		},
		mounted() {
			if (this.$dd.env.platform !== "notInDingTalk") {
				this.$dd.ready(() => {
					if (this.$dd.env.platform != 'ios') {
						this.platform = 'android'
					} else {
						this.platform = this.$dd.env.platform
					}
				})
			}
		},
	};
</script>
<style lang="scss" scoped>
	.layout {
		.el-container {
			@extend .layout;
			display: flex;
			flex-direction: column;

			.el-main {
				margin: 0 0;
				padding: 0 0;
				background: #F7F8F9;
			}

			.isIos-main {
				height: calc(100vh - 0.68rem);
				// overflow: auto;
				-webkit-overflow-scrolling: touch;
			}

			.isAndroid-main {
				height: 100vh;
				// overflow: auto;
				-webkit-overflow-scrolling: touch;
			}
		}
	}
</style>
